import { useEffect, useMemo } from "react";

import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";
import { CompatRouter } from "react-router-dom-v5-compat";

import { ErrorBoundary } from "App/ErrorBoundary";
import NotFound from "App/NotFound";
import { hardRedirect, routerBaseName } from "App/routes";
import Layout from "Components/layouts/Layout";
import Login from "Pages/login";

import getLoginRoutes from "./getLoginRoutes";
import { useRegalAuthContext } from "./RegalAuthContext";
import useThirdPartyScripts from "./useThirdPartyScripts";

function LoginOrAgentRoute() {
    const regalAuth = useRegalAuthContext();
    const userId = regalAuth.user.oktaUserId;

    useEffect(() => {
        if (userId) {
            hardRedirect("/agent");
        }
    }, [userId]);

    /**
     * @remaks : we dont want to see a FLASH of the login page
     */
    if (userId) {
        return null;
    }

    return <Login />;
}

export default function LoginAppContainer(): JSX.Element {
    useThirdPartyScripts();
    const loginRoutes = useMemo(() => getLoginRoutes({ loginOrRedirectComponent: LoginOrAgentRoute }), []);

    return (
        <Router basename={routerBaseName}>
            <CompatRouter>
                <ErrorBoundary>
                    <Layout pageReady>
                        <Switch>
                            {loginRoutes}
                            <Route key="fallback" exact path="/">
                                <Redirect to="/login" />
                            </Route>
                            <Route>
                                <NotFound />
                            </Route>
                        </Switch>
                    </Layout>
                </ErrorBoundary>
            </CompatRouter>
        </Router>
    );
}
