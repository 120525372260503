import { useState, useEffect } from "react";

import { message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { hardRedirect } from "App/routes";
import { usePageConfig } from "Components/layouts/Layout";
import { PaddedPage } from "Components/layouts/PaddedPage";
import { EmailForm } from "Pages/login/EmailForm/EmailForm";
import { PasswordForm } from "Pages/login/PasswordForm/PasswordForm";
import { signInWithCredentials } from "Services/AuthService";
import { getLogger } from "Services/LoggingService";
import { getGoogleSsoRequired } from "Services/MarketingApiService";
import { beginLogin, selectLoginBeganEmail } from "Services/state/users/UserSlice";

import GoogleSso from "./GoogleSso/GoogleSso";

import shared from "./AuthSharedAcross.module.scss";

enum Tab {
    email = "email",
    password = "password",
    googleSso = "googleSso",
}

const loginLogger = getLogger("Login");

/**
 * @todo all the versions of this and move to a shared file
 */
const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/;

export const SESSION_EXPIRED_MESSAGE = "You've been logged out of Regal. Please log in again.";

export default function Login(): JSX.Element {
    const { search } = useLocation();

    usePageConfig({
        pageTitle: "Log in",
        showSidebar: false,
    });

    const email = (useSelector(selectLoginBeganEmail) || "") as string;
    const [currentTab, setCurrentTab] = useState<Tab>(Tab.email);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");
    const dispatch = useDispatch();

    function setAndLogErrorMessage(errorMessage: string) {
        if (errorMessage) {
            loginLogger.error(`Login error: ${errorMessage}`);
        }
        setErrorMessage(errorMessage);
    }

    useEffect(() => {
        const queryParams = new URLSearchParams(search);

        if (queryParams.get("invalidGoogleSso") === "true") {
            setAndLogErrorMessage(
                "The email you entered is not associated with a Regal Voice account. Please try again using the correct email."
            );

            return;
        }

        const presetEmail = queryParams.get("email");
        if (presetEmail && presetEmail.match(EMAIL_REGEX)) {
            dispatch(beginLogin(presetEmail));
            handleEmailSubmit(presetEmail);
        }

        const expired = queryParams.get("expired");
        if (expired) {
            const key = Math.random();
            message.warning({
                key,
                content: SESSION_EXPIRED_MESSAGE,
                duration: 10,
                onClick: () => {
                    message.destroy(key);
                },
            });
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps -- onMount
    }, []);

    useEffect(() => {
        loginLogger.log(`Entering login flow stage: ${currentTab}`);
    }, [currentTab]);

    function handleBackToEmailEdit() {
        message.destroy();
        setAndLogErrorMessage("");
        setCurrentTab(Tab.email);
    }

    async function handleEmailSubmit(email: string) {
        message.destroy();
        try {
            localStorage.removeItem("okta-cache-storage");
            const ssoRequired = await getGoogleSsoRequired(email);
            loginLogger.log("Successfully submitted email", { usingGoogleSso: ssoRequired });
            setCurrentTab(ssoRequired.required ? Tab.googleSso : Tab.password);
        } catch (error) {
            loginLogger.error(`Error when checking if Google SSO is required.`, { email, error });
            setErrorMessage("Email is invalid");
        }
    }

    async function handleFormSubmit(password: string) {
        message.destroy();
        setSubmitting(true);
        setAndLogErrorMessage("");

        try {
            await signInWithCredentials(email, password);
            loginLogger.log("Successfully logged in");
            // add a variable query string to help prevent caching of html
            hardRedirect(`/agent?t=${Date.now()}`);
        } catch (err: any) {
            setAndLogErrorMessage(err instanceof Error ? err.message : String(err));
        } finally {
            setSubmitting(false);
        }
    }

    return (
        <PaddedPage className={shared.containerCentered}>
            <div className={shared.panel}>
                {currentTab == Tab.email && (
                    <EmailForm
                        submitting={submitting}
                        onSubmit={handleEmailSubmit}
                        errorMessage={errorMessage}
                        setErrorMessage={setAndLogErrorMessage}
                    />
                )}
                {currentTab == Tab.password && (
                    <PasswordForm
                        submitting={submitting}
                        onSubmit={handleFormSubmit}
                        errorMessage={errorMessage}
                        setErrorMessage={setAndLogErrorMessage}
                        editEmail={handleBackToEmailEdit}
                    />
                )}
                {currentTab == Tab.googleSso && <GoogleSso />}
            </div>
            <div className={shared.centered}>
                <span className="subcopy">Don&apos;t have an account yet?</span>
                &nbsp;
                <a href="mailto:support@regalvoice.com">Contact us</a>
            </div>
        </PaddedPage>
    );
}
