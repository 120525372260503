import { useEffect, useRef } from "react";

import { RVButton } from "Components/elements/RVButton/RVButton";
import { getApiUrl } from "Services/ProxyConfig";
import { PUBLIC_ENV_GOOGLE_SSO_CLIENT_ID } from "Utils/EnvironmentVars";

import styles from "./GoogleSso.module.scss";

const clientId = PUBLIC_ENV_GOOGLE_SSO_CLIENT_ID;
const loginUri = `${getApiUrl()}/sso/google`;

/**
 * For more info: https://developers.google.com/identity/gsi/web/guides/display-button#html
 */
export default function GoogleSso(): JSX.Element {
    const signinButton = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const script = document.createElement("script");

        script.src = "https://accounts.google.com/gsi/client";
        script.async = true;
        script.defer = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    function handleButtonClick() {
        (signinButton.current?.querySelector("#button-label") as HTMLElement)?.click();
    }

    return (
        <div className={styles.googleSsoContainer}>
            <h2>Continue to Sign in with Google</h2>
            <div style={{ display: "none" }}>
                <div
                    id="g_id_onload"
                    data-client_id={clientId}
                    data-ux_mode="popup"
                    data-login_uri={loginUri}
                    data-nonce=""
                    data-auto_prompt="false"
                    data-auto_select="false"
                ></div>

                <div
                    data-type="standard"
                    data-shape="rectangular"
                    data-theme="outline"
                    data-size="medium"
                    data-logo_alignment="left"
                    className="g_id_signin"
                    ref={signinButton}
                ></div>
            </div>

            <RVButton icon="google-logo" className={styles.button} size="large" onClick={handleButtonClick}>
                Sign in with Google
            </RVButton>
        </div>
    );
}
