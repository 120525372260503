import { Route } from "react-router-dom";

import ForgotPassword from "Pages/login/forgot-password";
import ResetPassword from "Pages/login/forgot-password/edit";
import Sso from "Pages/login/sso";

type LoginRoutesProps = {
    loginOrRedirectComponent: React.ComponentType;
};

export default function getLoginRoutes({ loginOrRedirectComponent: LoginOrRedirect }: LoginRoutesProps): JSX.Element[] {
    return [
        <Route key="/login/forgot-password" exact path="/login/forgot-password">
            <ForgotPassword />
        </Route>,

        <Route key="/login/forgot-password/:recoveryToken" exact path="/login/forgot-password/:recoveryToken">
            <ResetPassword />
        </Route>,

        <Route key="/login" exact path="/login">
            <LoginOrRedirect />
        </Route>,

        <Route key="/login/sso/:sessionToken" exact path="/login/sso/:sessionToken">
            <Sso />
        </Route>,
    ];
}
