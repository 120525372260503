import { StrictMode } from "react";

import { LDProvider } from "launchdarkly-react-client-sdk";
import { createRoot } from "react-dom/client";
import { Provider as ReduxProvider } from "react-redux";

import LoginAppContainer from "App/main/LoginAppContainer";
import { prepareDOM, initLDClient, ANONYMOUS_LD_USER } from "App/main/Utils";
import { reduxStore } from "Services/state/Storage";
import "Styles/globals.scss";

(async function login(appRoot) {
    const ldClient = initLDClient(ANONYMOUS_LD_USER);
    appRoot.render(
        <StrictMode>
            <LDProvider ldClient={ldClient} clientSideID="">
                <ReduxProvider store={reduxStore}>
                    <LoginAppContainer />
                </ReduxProvider>
            </LDProvider>
        </StrictMode>
    );
})(createRoot(prepareDOM()));
