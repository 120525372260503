import { useEffect, useRef } from "react";

import { Form, Input } from "antd";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import { RVButton } from "Components/elements/RVButton/RVButton";
import { RVForm } from "Components/elements/RVForm/RVForm";
import { selectLoginBeganEmail } from "Services/state/users/UserSlice";

import ErrorMessage from "../ErrorMessage";

import sharedStyles from "../AuthSharedAcross.module.scss";
import styles from "./PasswordForm.module.scss";

export function PasswordForm({
    submitting,
    onSubmit,
    errorMessage,
    setErrorMessage,
    editEmail,
}: {
    submitting: boolean;
    onSubmit: (password: string) => void;
    errorMessage: string;
    setErrorMessage: (errorMessage: string) => void;
    editEmail: () => void;
}): JSX.Element {
    const [form] = Form.useForm();
    const email = useSelector(selectLoginBeganEmail);
    const passwordRef = useRef<any>(null);

    useEffect(() => {
        passwordRef.current?.focus();
    }, []);

    function doSubmit(formData: any): void {
        onSubmit(formData.password);
    }

    return (
        <div className={sharedStyles.columnContent}>
            <h2 className="centered">Welcome back</h2>
            <span className="subcopy">
                <a onClick={editEmail}>&larr;</a> {email}
            </span>
            <RVForm className={sharedStyles.form} form={form} layout="vertical" onFinish={doSubmit} size="large">
                <Form.Item label="Password" className={sharedStyles.formItem}>
                    <span className={styles.forgotLink}>
                        <Link to="/login/forgot-password">Forgot password?</Link>
                    </span>
                    <Form.Item
                        name="password"
                        noStyle
                        rules={[
                            {
                                required: true,
                                message: "Password is required",
                            },
                        ]}
                    >
                        <Input type="password" ref={passwordRef} size="large" onChange={() => setErrorMessage("")} />
                    </Form.Item>
                    {!!errorMessage && <ErrorMessage errorMessage={errorMessage} />}
                </Form.Item>
                <Form.Item>
                    <RVButton block={true} size="large" type="primary" htmlType="submit" loading={submitting}>
                        {submitting ? "Logging in..." : "Login"}
                    </RVButton>
                </Form.Item>
            </RVForm>
        </div>
    );
}
