import { useEffect, useRef } from "react";

import { Form, Input } from "antd";
import { useDispatch, useSelector } from "react-redux";

import { RVButton } from "Components/elements/RVButton/RVButton";
import { RVForm } from "Components/elements/RVForm/RVForm";
import { beginLogin, selectLoginBeganEmail } from "Services/state/users/UserSlice";

import ErrorMessage from "../ErrorMessage";

import styles from "../AuthSharedAcross.module.scss";

/**
 * @todo all the versions of this and move to a shared file
 */
const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/;
export function EmailForm({
    submitting,
    onSubmit,
    errorMessage,
    setErrorMessage,
}: {
    submitting: boolean;
    onSubmit: (email: string) => Promise<void>;
    setErrorMessage: (errorMessage: string) => void;
    errorMessage: string;
}): JSX.Element {
    const [form] = Form.useForm<{ email: string }>();
    const currentEmail = useSelector(selectLoginBeganEmail) || "";
    const emailRef = useRef<any>(null);
    const dispatch = useDispatch();

    useEffect(() => emailRef.current?.focus(), [emailRef.current]);

    function doSubmit(formData: any) {
        dispatch(beginLogin(formData.email));
        onSubmit(formData.email);
    }

    function setEmailValidityMessage() {
        const { email } = form.getFieldsValue();
        const errors = [];

        if (/\s/.test(email)) {
            errors.push("spaces");
        }

        if (/[^a-zA-Z0-9._%+-.\s@]/.test(email)) {
            errors.push("invalid special characters");
        }

        if (errors.length) {
            setErrorMessage(`Email must not have ${errors.join(" or ")}`);
            return;
        }

        setErrorMessage("Email is invalid");
    }

    return (
        <div className={styles.columnContent}>
            <h2 className="centered">Log in to Regal</h2>
            <RVForm className={styles.form} form={form} layout="vertical" onFinish={doSubmit} size="large">
                <Form.Item label="Email" htmlFor="email" className={styles.formItem}>
                    <Form.Item
                        name="email"
                        noStyle
                        // TODO updtae to used the shared input validation rules
                        rules={[
                            {
                                required: true,
                                message: "Email is required",
                            },
                            {
                                message: "Email is invalid",
                                pattern: EMAIL_REGEX,
                            },
                        ]}
                        initialValue={currentEmail}
                    >
                        <Input
                            ref={emailRef}
                            size="large"
                            onChange={() => setErrorMessage("")}
                            onInvalid={(e) => {
                                e.preventDefault();
                                setEmailValidityMessage();
                            }}
                        />
                    </Form.Item>
                    {!!errorMessage && <ErrorMessage errorMessage={errorMessage} />}
                </Form.Item>
                <Form.Item>
                    <RVButton block={true} size="large" type="primary" htmlType="submit" loading={submitting}>
                        {submitting ? "Loading..." : "Continue"}
                    </RVButton>
                </Form.Item>
            </RVForm>
        </div>
    );
}
