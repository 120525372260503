import { useEffect, useState } from "react";

import { Form, Input } from "antd";
import { useHistory, useParams } from "react-router-dom";

import { hardRedirect } from "App/routes";
import Panel from "Components/containers/Panel/Panel";
import { RVButton } from "Components/elements/RVButton/RVButton";
import { usePageConfig } from "Components/layouts/Layout";
import { PaddedPage } from "Components/layouts/PaddedPage";
import { checkPasswordRecoveryToken, resetPassword, signInWithCredentials } from "Services/AuthService";
import { renderErrorMessage } from "Services/LoggingService";

import shared from "../AuthSharedAcross.module.scss";

export const TOAST_MESSAGE = "You have changed your password successfully";

interface ParamTypes {
    recoveryToken: string;
}

export default function ResetPassword(): JSX.Element {
    const router = useHistory();
    const [form] = Form.useForm();
    const [loading, setLoading] = useState<boolean>(false);
    const [resetAttempts, setResetAttempts] = useState(0);

    const { recoveryToken } = useParams<ParamTypes>();

    usePageConfig({
        pageTitle: "Reset password",
        showSidebar: false,
    });

    useEffect(() => {
        async function checkRecoveryTokenValidity(recoveryToken: string): Promise<void> {
            try {
                setLoading(true);
                const { email } = await checkPasswordRecoveryToken(recoveryToken);
                form.setFieldsValue({ email });
                setLoading(false);
            } catch (e) {
                if (e instanceof Error) {
                    renderErrorMessage({ content: e, error: e });
                }
                await router.replace("/login");
            }
        }
        if (recoveryToken) {
            checkRecoveryTokenValidity(recoveryToken);
        }
    }, [recoveryToken, form, router]);

    async function triggerReset(values: any): Promise<void> {
        const newPassword = values.password;
        setLoading(true);
        setResetAttempts(resetAttempts + 1);
        try {
            await resetPassword({
                recoveryToken,
                newPassword,
            });
            await signInWithCredentials(values.email, values.password);
            hardRedirect("/");
        } catch (e) {
            setLoading(false);
            if (e instanceof Error) {
                renderErrorMessage({ content: e, error: e, duration: 10 });
            }
        }
    }

    return (
        <PaddedPage className={shared.containerCentered}>
            <Panel size={Panel.Size.px400} className={shared.panel}>
                <div className={shared.columnContent}>
                    <h2 className="centered">Reset your password</h2>
                    <Form
                        className={shared.form}
                        form={form}
                        layout="vertical"
                        name="reset-password-form"
                        onFinish={triggerReset}
                        size="large"
                    >
                        <Form.Item
                            className={shared.formItem}
                            name="email"
                            label="Email"
                            requiredMark={false}
                            rules={[{ required: true }]}
                        >
                            <Input size="large" data-testid="email" readOnly={true} disabled={true} />
                        </Form.Item>
                        <Form.Item
                            className={shared.formItem}
                            name="password"
                            label="Password"
                            rules={[{ required: true, min: 8, message: "Password must be at least 8 characters" }]}
                            requiredMark={true}
                        >
                            <Input size="large" data-testid="password" type="password" />
                        </Form.Item>
                        <Form.Item
                            className={shared.formItem}
                            name="confirmPassword"
                            label="Re-enter new password"
                            requiredMark={true}
                            rules={[
                                { required: true },
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        if (!value || getFieldValue("password") === value) {
                                            return Promise.resolve();
                                        }

                                        return Promise.reject("The two passwords that you entered do not match!");
                                    },
                                }),
                            ]}
                        >
                            <Input size="large" data-testid="confirmPassword" type="password" />
                        </Form.Item>
                        <Form.Item>
                            <RVButton
                                data-testid="resetPassword"
                                loading={loading}
                                type="primary"
                                htmlType="submit"
                                block={true}
                                disabled={loading}
                                size="large"
                            >
                                Reset Password
                            </RVButton>
                        </Form.Item>
                    </Form>
                </div>
            </Panel>
        </PaddedPage>
    );
}
