import { useRef, useState } from "react";

import { Form, Input, message } from "antd";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import Panel from "Components/containers/Panel/Panel";
import { RVButton } from "Components/elements/RVButton/RVButton";
import { RVForm } from "Components/elements/RVForm/RVForm";
import { usePageConfig } from "Components/layouts/Layout";
import { PaddedPage } from "Components/layouts/PaddedPage";
import { sendResetEmail } from "Services/AuthService";
import { renderErrorMessage } from "Services/LoggingService";
import { selectLoginBeganEmail } from "Services/state/users/UserSlice";

import shared from "../AuthSharedAcross.module.scss";

export default function ForgotPassword(): JSX.Element {
    usePageConfig({
        pageTitle: "Reset password",
        showSidebar: false,
    });

    const [form] = Form.useForm();
    const [loading, setLoading] = useState<boolean>(false);
    const currentEmail = useSelector(selectLoginBeganEmail);
    const router = useHistory();
    const emailRef = useRef<any>(null);

    async function handleResetPassword(formData: any): Promise<void> {
        setLoading(true);
        try {
            await sendResetEmail(formData.email);
            message.success(
                "If your email is in our system, you will receive password reset instructions in a few minutes."
            );
        } catch (e: any) {
            renderErrorMessage({ content: "Something went wrong. Please try again", error: e });
        }
        setLoading(false);
        router.replace("/login");
    }

    // Todo: Make this a component
    function RenderForgotPassword(): JSX.Element {
        return (
            <div className={shared.columnContent}>
                <h2 className="centered">Send reset instructions</h2>
                <RVForm
                    className={shared.form}
                    form={form}
                    layout="vertical"
                    onFinish={handleResetPassword}
                    size="large"
                >
                    <Form.Item
                        className={shared.formItem}
                        label="Email"
                        initialValue={currentEmail}
                        rules={[{ required: true, message: "Email is required" }]}
                        name="email"
                    >
                        <Input ref={emailRef} size="large" pattern=".+@.+\..+" />
                    </Form.Item>
                    <Form.Item>
                        <RVButton block={true} size="large" type="primary" htmlType="submit" loading={loading}>
                            Send Reset Instructions
                        </RVButton>
                    </Form.Item>
                </RVForm>
            </div>
        );
    }

    return (
        <PaddedPage className={shared.containerCentered}>
            <Panel className={shared.panel} size={Panel.Size.px400}>
                <RenderForgotPassword />
            </Panel>
            <div className={shared.centered}>
                <span className={shared.subcopy}>Don&apos;t have an account yet?</span>
                &nbsp;
                <a href="mailto:support@regalvoice.com">Contact us</a>
            </div>
        </PaddedPage>
    );
}
