import { useEffect } from "react";

import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import { hardRedirect } from "App/routes";
import { signInWithSession } from "Services/AuthService";
import { getLogger } from "Services/LoggingService";
import { completeLogin } from "Services/state/users/UserSlice";

const loginLogger = getLogger("Login");

interface ParamTypes {
    sessionToken: string;
}

export default function Sso(): JSX.Element {
    const dispatch = useDispatch();
    const { sessionToken } = useParams<ParamTypes>();

    useEffect(() => {
        signInWithSession({ sessionToken })
            .then((loggedIn) => {
                if (loggedIn) {
                    dispatch(completeLogin());
                }
            })
            .then(() => {
                // add a variable query string to help prevent caching of html
                hardRedirect(`/agent?t=${Date.now()}`);
                loginLogger.log("Successfully logged in", { usedSso: true });
            })
            .catch((err) => {
                loginLogger.error(err);
                throw err;
            });
    }, []);

    return <></>;
}
